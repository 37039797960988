<template>
    <Toast />
    <div class="card">
        <DataTable :value="tasklist" :lazy="true" v-model:selection="selectedData" selectionMode="single"
            :scrollable="true" stripedRows scrollHeight="650px" :loading="loading" scrollDirection="both"
            :paginator="true" :rows="pagesize" :virtualScrollerOptions="vsOptionFun()"
            :rowsPerPageOptions="[50, 100, 200, 500]" :totalRecords="totalRecords" :first="first"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3" @page="taskPage($event)">
            <Column field="subject" header="标题" style="flex-grow:1; flex-basis:300px" frozen>
                <template #body="slotProps">
                    <Button :label="slotProps.data.subject" @click="opendetail(slotProps.data)" class="p-button-link" />
                </template>
            </Column>
            <Column field="statename" header="环节" style="flex-grow:1; flex-basis:200px"></Column>
            <Column field="flowname" header="流程" style="flex-grow:1; flex-basis:150px"></Column>
            <Column field="createtime" header="创建时间" style="flex-grow:1; flex-basis:160px"></Column>
            <Column field="creater" header="创建人" style="flex-grow:1; flex-basis:150px"></Column>
        </DataTable>
    </div>
    <Dialog header="流程信息" v-model:visible="displayPosition" :style="{width: '50vw'}" :position="position"
        :maximizable="true" :modal="true">
        <FlowHistory ref="flowhistory" :flowHistoryData="flowhistoryJson" :title="recordsubject" />
        <template #footer>
            <Button label="关闭" icon="pi pi-power-off" @click="closePosition" autofocus />
        </template>
    </Dialog>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :closeButLoadingMethod="closeButLoading" :currentRow="currentRow"
                :freshListMethod="freshList" :closeParentMehod="closeMaximizable"
                :childNewDataConfig="childNewDataConfig" :dropOtherConfig="dropOtherConfig"
                :childTableFooter="childTableFooter" :loadDataAfterMethod="loadDataAfter"
                :CellEditCompleteMethod="cellEditComplete" :childaloneshowConfig="childaloneshowConfig"
                :childExpandePageConfig="childExpandeConfig" :isReadOnly="false" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button :label="currentState" icon="pi pi-sitemap" class="p-button-raised p-button-text mr-2"
                    @click="openLeftPosition" />
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" />
                <Button label="提交" icon="pi pi-check" @click="submitData" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
</template>
<script>
    import {
        ref,
    } from 'vue';
    import CommonJs from '../js/Common.js';
    import ShipmentBoxJs from '../js/ShipmentBox';
    import requests from '../service/newaxios';
    import * as Base64 from 'js-base64';
    import NewUniversalPage from '../components/NewUniversalPage.vue';
    import FlowHistory from '../components/FlowHistory.vue';
    import MessageTip from '../components/Message';

    export default {
        data() {
            return {
                selectedData: ref(),
                currentRow: null,
                flowhistoryJson: null,
                loading: false,
                butLoading: false,
                displayMaximizable: false,
                displayPosition: false,
                position: 'bottomleft',
                recordsubject: '',
                currentState: '',
                totalRecords: -1,
                first: 0,
                pagesize: 50,
                tasklist: [],
                currencyDict: [],
                childNewDataConfig: {},
                dropOtherConfig: {},
                childTableFooter: {},
                childaloneshowConfig: {},
                childExpandeConfig: {},
            }
        },
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            CommonJs.loadDictFromCache('Currency', 'dictionaryCurrency', this.currencyDict, this.setloadDictFromCache);
            this.loadData(1);
        },
        methods: {
            setloadDictFromCache(v_currencyDict) {
                this.currencyDict = v_currencyDict;
            },
            freshList() {
                this.loadData(1);
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            loadData(v_page) {
                this.loading = true;
                var sysid = this.$route.query.sysid;
                if (sysid == null) {
                    sysid = '';
                }
                var taskboxlistwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: [{
                        name: 'sysid',
                        value: sysid,
                        operation: '=',
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{taskboxlist(where:" + JSON.stringify(taskboxlistwhere) +
                    "){id workflowid rootworkflowid boxid flowid stateid sysid isopen subject statename flowname bindpage flowguid createtime creater}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.tasklist = jsonData.data.taskboxlist;
                        console.log(this.tasklist);
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                        this.loading = false;
                    }
                });
            },
            taskPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                console.log(page);
                this.loadData(page + 1);
            },
            opendetail(v_row) {
                this.currentRow = v_row;
                this.recordsubject = v_row.subject;
                this.currentState = '流程信息-当前环节:' + v_row.statename;
                if (v_row.bindpage != '') {
                    this.initDetail(v_row.bindpage)
                    this.displayMaximizable = true;
                } else {
                    MessageTip.warnmsg('类型名称不能为空');
                }
            },
            flowDialogHide() {
                document.getElementById("flowFrame").src = "";
                this.loadData(1);
            },
            closeMaximizable() {
                this.closeButLoading();
                this.displayMaximizable = false;
            },
            submitData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo();
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            openLeftPosition() {
                this.flowhistoryJson = this.$refs.universalpage.flowhistory;
                this.displayPosition = true;
            },
            closePosition() {
                this.displayPosition = false;
            },
            initDetail(v_bindpage) {
                console.log('initDetail...' + v_bindpage);
                if (v_bindpage == 'shipment') {
                    ShipmentBoxJs.initDetail();
                }
            },
            loadDataAfter(v_mainelements, v_childelements, v_originalData) {
                console.log(v_mainelements);
                console.log(v_childelements);
                console.log(v_originalData);
                if (this.currentRow.bindpage == 'shipment') {
                    ShipmentBoxJs.loadDataAfter(v_mainelements, v_childelements, v_originalData);
                }
            },
            cellEditComplete(tablename, field, data, rowdata, childTableFooterObj, v_childelements,
                v_alonechildelements, v_elements) {
                console.log('cellEditCompleteMethod..');
                console.log(tablename);
                console.log(field);
                console.log(data);
                console.log(rowdata);
                console.log(childTableFooterObj);
                console.log(v_childelements);
                console.log(v_alonechildelements);
                console.log(v_elements);
                if (this.currentRow.bindpage == 'shipment') {
                    ShipmentBoxJs.cellEditComplete(tablename, field, data, rowdata, childTableFooterObj,
                        v_childelements,
                        v_alonechildelements, v_elements);
                }
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            dialogClose() {
                this.butLoading = false;
            }
        },
        components: {
            NewUniversalPage,
            FlowHistory,
        }
    }
</script>